import React from 'react'
import BaseCommandPage from './index'

class GoogleCommandPage extends React.Component {
  render() {
    return (
      <BaseCommandPage initialTabIndex={0} location={this.props.location} />
    )
  }
}

export default GoogleCommandPage
